import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import { getDefaultVideoSettings } from '@uq-helpers';
import { UqTextHighlight, UqCustomerLogosV2, UqPromoBanner, UqHeroV2, UqLayoutV2, UqContainer, UqContainerV2, UqLegacyRequestDemoButton, UqStatsBlock, UqSolutionsBlock, UqQuoteCarousel, UqRequestDemoBanner, UqHeroBleeding, UqPage, UqFigure, UqAppBarV2, UqNavbarV2, UqFooterV2, UqVideoV2, UqDialog, UqZendeskQuote } from '@uq-components';
import { UqAnimationInterval } from '../components/v2/layout/animation';
import { figureRowShape } from '../models';
import { Content } from '../content/v2/home';

import * as styles from './index.module.scss';

const VIDEO_CONFIG = {
  autoplay: true,
  settings: getDefaultVideoSettings('vimeo'),
  url: Content.hero.videoUrl,
};

UqHomePage.propTypes = {
  data: PropTypes.shape({
    figures: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: figureRowShape,
        }),
      ),
    }),
    securityFigure: figureRowShape,
  }),
};

export default function UqHomePage(props) {
  const { securityFigure } = props.data;
  const figures = props.data.figures.edges;

  const playerRef = useRef(null);
  const videoOverlayRef = useRef(null);

  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <UqPage config={{ seo: Content.seo }}>
      <UqLayoutV2>
        <UqPromoBanner />
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqHeroV2>
          <UqContainerV2
            className={styles.heroContainer}
            fill
            overflowX
          >
            <UqHeroBleeding
              image={Content.hero.image}
              subtitle={Content.hero.subtitle}
              renderTitle={renderTitle}
              renderActions={renderHeroActions}
            />
          </UqContainerV2>
        </UqHeroV2>

        <div className={styles.logos}>
          <UqContainerV2>
            <UqCustomerLogosV2 />
          </UqContainerV2>
        </div>

        <div className={styles.figures}>
          {figures.map((figure, index) => (
            <div
              key={index}
              className={classNames({ [styles.backdrop]: index % 2 === 0 })}
            >
              <UqContainerV2
                theme="light"
                fill
              >
                <UqFigure reverse={!figure.node.textAlign}>
                  <UqFigure.Content>
                    <UqFigure.Title>{figure.node.title}</UqFigure.Title>
                    <span>{figure.node.description.description}</span>

                    <UqFigure.Actions actions={figure.node.actions} />
                  </UqFigure.Content>

                  <UqFigure.Image src={figure.node.image.url} />
                </UqFigure>
              </UqContainerV2>
            </div>
          ))}
        </div>

        <section className={styles.zendeskQuote}>
          <UqContainerV2>
            <UqZendeskQuote />
          </UqContainerV2>
        </section>

        <UqContainer>
          <section className={styles.beyondSurveys}>
            <section className={styles.title}>
              <UqTextHighlight as="h2">
                {'**Stop** drowning in a \n sea of noisy user data'}
              </UqTextHighlight>
            </section>

            <UqFigure fit>
              <UqFigure.Content
                className={styles.content}
                fill
              >
                <UqFigure.Title
                  variant="h4"
                  fill
                >
                  {'Before unitQ:\n Retrospective, manual user insights'}
                </UqFigure.Title>

                <span>
                  Teams have been left with surveys, user interviews, and analysis of support tickets to understand what users like,
                  dislike, and want more of. Aggregating feedback from these disparate sources requires coordination
                  between teams and results in manual data that&apos;s hard to action on and quickly becomes stale.
                </span>

              </UqFigure.Content>

              <UqFigure.Image
                src="/images/v2/home/old-way.png"
                fill
              />
            </UqFigure>

            <UqFigure fit reverse>
              <UqFigure.Content
                className={styles.content}
                fill
              >
                <UqFigure.Title
                  variant="h4"
                  fill
                >
                  {'With unitQ:\n Get critical insights fast\n from user feedback'}
                </UqFigure.Title>

                <span>
                  unitQ makes it possible for product, engineering and CX teams to discover how users react to product launches,
                  pinpoint high impact bugs, identify trending support issues and more - all in real time.
                  unitQ AI AI insights empower all teams to deliver what users like and want more of to every single user.
                </span>

              </UqFigure.Content>

              <UqFigure.Image
                src="/images/v2/home/with-unitq.png"
                fill
              />
            </UqFigure>
          </section>
        </UqContainer>
        <UqContainer
          className={styles.backgroundContainer}
          fullWidth
          background="dark"
          logoProps={{
            anchor: 'topLeft',
            dark: true,
          }}
        >
          <UqContainer overflowY>
            <section className={styles.statsBlock}>
              <UqStatsBlock {...Content.statsConfig} />
            </section>
            <UqSolutionsBlock
              title={Content.solutions.title}
              withConfetti
            />
          </UqContainer>
        </UqContainer>
        <UqContainer>
          <section className={styles.quotes}>
            <UqQuoteCarousel {...Content.quoteConfig} />
          </section>
          <UqFigure className={styles.security}>
            <UqFigure.Content>
              <UqFigure.Title>{securityFigure.title}</UqFigure.Title>
              <p>{securityFigure.description.description}</p>

              <UqFigure.Actions actions={securityFigure.actions} />
            </UqFigure.Content>

            <UqFigure.Image src={securityFigure.image.url} />
          </UqFigure>
          <UqRequestDemoBanner />
        </UqContainer>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );

  function renderTitle() {
    const { text, config } = Content.hero.title;

    return (
      <UqTextHighlight
        as="h1"
        className={styles.title}
        renderHighlight={(value) => (
          <>
            <br className={styles.lineBreak} />
            <span className={styles.staticStage}>
              {value}
            </span>
            <UqAnimationInterval
              classes={{
                active: styles.active,
                inactive: styles.inactive,
                root: styles.stage,
              }}
              interval={2500}
              delay={10000}
            >
              {config.map((item, index) => (
                <div
                  key={index}
                  className={styles.item}
                >
                  {item}
                </div>
              ))}
            </UqAnimationInterval>
          </>
        )}
      >
        {text}
      </UqTextHighlight>
    );
  }

  function renderHeroActions() {
    return (
      <div className={styles.actions}>
        <UqLegacyRequestDemoButton className={classNames('extra-wide', styles.demoButton)}>
          Get a Demo — with Your Data!
        </UqLegacyRequestDemoButton>

        <UqVideoV2 config={VIDEO_CONFIG}>
          <UqVideoV2.Trigger onClick={() => videoOverlayRef.current.open()}>
            Watch Video
          </UqVideoV2.Trigger>

          {isMounted && (
            <UqDialog
              ref={videoOverlayRef}
              onClose={() => playerRef.current.seekTo(0)}
            >
              <UqVideoV2.Player ref={playerRef} />
            </UqDialog>
          )}
        </UqVideoV2>
      </div>
    );
  }
}

export const pageQuery = graphql`
  query {
    figures: allContentfulFigureRow(filter: { pageId:{ in: "home" } }, sort: {order: ASC, fields: order}) {
      edges {
        node {
          actions {
            href
            label
            type
          }
          description {
            description
          }
          image {
            url
          }
          order
          textAlign
          title
        }
      }
    }
    securityFigure: contentfulFigureRow(pageId: {eq: "*-security"}) {
      actions {
        href
        label
        type
      }
      description {
        description
      }
      image {
        url
      }
      order
      textAlign
      title
    }
  }
`;
